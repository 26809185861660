import { Link } from 'react-router-dom'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'

/**
 * Renders links to sign-in, sign-up and forgot-password pages with the React Router DOM.
 * @param {'sign-in' | 'sign-up' | 'forgot-password'} formType - the form where the links are used.
 */
export const AuthFormLinks = ({
  formType,
  redirectUrl = '',
}: {
  formType: 'sign-in' | 'sign-up' | 'forgot-password'
  redirectUrl?: string
}): JSX.Element => {
  const liStyle = 'mb-1'
  const spanStyle = 'text-sm mr-1 font-semibold'
  const linkStyle = 'text-sm font-semibold text-blue-500'
  const appendRedirectUrl = (url: string) => {
    return redirectUrl
      ? `${url}?redirectUrl=${encodeURIComponent(redirectUrl)}`
      : url
  }

  return (
    <ul className='text-center'>
      {formType !== 'sign-in' && (
        <li className={liStyle}>
          <span className={spanStyle}>Have account already?</span>
          <Link
            to={appendRedirectUrl(includeCurrentLocaleToPath('/sign-in'))}
            className={linkStyle}
          >
            Sign In
          </Link>
        </li>
      )}
      {formType !== 'sign-up' && (
        <li className={liStyle}>
          <span className={spanStyle}>Don&apos;t have account?</span>
          <Link
            to={appendRedirectUrl(includeCurrentLocaleToPath('/sign-up'))}
            className={linkStyle}
          >
            Sign Up
          </Link>
        </li>
      )}
      {formType !== 'forgot-password' && (
        <li className={liStyle}>
          <span className={spanStyle}>Forgot password?</span>
          <Link
            to={appendRedirectUrl(
              includeCurrentLocaleToPath('/forgot-password'),
            )}
            className={linkStyle}
          >
            Reset password
          </Link>
        </li>
      )}
    </ul>
  )
}
